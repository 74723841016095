<template>
  <v-card flat>
    <b> &nbsp;&nbsp; BO Test at {{ date }} </b>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="2" md="2" align="center"> </v-col>
      <v-col cols="12" sm="2" md="2" align="center">Initial pressure </v-col>
      <v-col cols="12" sm="2" md="2" align="center">Bleed of to</v-col>
      <v-col cols="12" sm="2" md="2" align="center">Continous flow</v-col>
      <v-col cols="12" sm="2" md="2" align="center">1 Hr PBU</v-col>
      <v-col cols="12" sm="2" md="2" align="center">24 Hr PBU</v-col>
    </v-row>
    <v-row style="margin: 4px">
      <v-row class="rowh2" dense v-for="item in list" :key="item.id">
        <v-col cols="12" sm="2" md="2">
          <div style="margin: 8px">
            <span>{{ item.label }}</span>
          </div></v-col
        >
        <v-col cols="12" sm="2" md="2">
          <v-text-field
            align-self="center"
            class="rowh"
            autocomplete="off"
            return-object
            v-model="item.i_pressure"
            outlined
            type="number"
            :readonly="true"
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-text-field
            align-self="center"
            class="rowh"
            autocomplete="off"
            return-object
            v-model="item.f_pressure"
            outlined
            type="number"
            dense
            :readonly="true"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-text-field
            class="rowh"
            autocomplete="off"
            return-object
            v-model="item.c_flow"
            type="number"
            outlined
            dense
            :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-text-field
            class="rowh"
            autocomplete="off"
            return-object
            v-model="item.f_pressure_12hrs"
            type="number"
            outlined
            dense
            :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-text-field
            class="rowh"
            return-object
            autocomplete="off"
            v-model="item.f_pressure_24hrs"
            type="number"
            outlined
            dense
            :readonly="true"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    auth() {
      let a = "01007";

      return a;
    },
    date() {
      if (this.list.length > 0) return this.list[0].testdate;
      else return null;
    },
  },
  watch: {},
  methods: {},
  created() {
    this.pressure_unit = this.$store.state.options[0].pressure;
  },
};
</script>
<style>
.rowh2 {
  height: 40px !important;
  margin-bottom: 8px !important;
}
</style>
